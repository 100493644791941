import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Typography from 'common/Typography';

import './LdsPage.scss';

const LdsPage: FC<LdsPageTypes.LdsPageProps> = ({
  data: {
    ldsPage: { urls, seo, title, content, additionalText },
  },
}) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  return (
    <Layout {...{ urls, seo }} background="sauvignon">
      <Container gutter className="lds-page">
        {additionalText ? (
          <Typography as="p" size={{ base: 35, md: 45 }} className="lds-page__title">
            {additionalText}
          </Typography>
        ) : null}
        <Typography as="h1" size={{ base: 35, md: 45 }} className="lds-page__title">
          {title}
        </Typography>
        <Typography
          dangerouslySetInnerHTML={content}
          size={{ base: 15, md: 20 }}
          className="lds-page__description"
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query LdsPageQuery($lang: String, $path: [String]) {
    ldsPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      title
      content
      additionalText
    }
  }
`;

export default LdsPage;
